import { GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { VerticalNavDefaultPageTemplate } from '@components/templates/VerticalNavDefaultPageTemplate'
import { ProfileSingleColumnContentTemplate } from '@components/templates/ProfileSingleColumnContentTemplate'
import { PrimaryHeading } from '@components/atoms/PrimaryHeading'
import { MyHead } from '@components/molecules/MyHead'

const Custom404: NextPage = () => {
  const { t } = useTranslation(['error'])
  return (
    <VerticalNavDefaultPageTemplate>
      <MyHead title='404: Not Found' />
      <ProfileSingleColumnContentTemplate>
        <PrimaryHeading backgroundText='404'>404: Not Found</PrimaryHeading>
        <p>{t('お探しのページは見つかりませんでした。')}</p>
      </ProfileSingleColumnContentTemplate>
    </VerticalNavDefaultPageTemplate>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['global', 'error'])),
  },
})

export default Custom404
