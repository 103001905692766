import { FC, PropsWithChildren } from 'react'
import styles from './index.module.scss'

type Props = {
  backgroundText: string
  htmlTagType?: 'h1' | 'h2'
}

export const PrimaryHeading: FC<PropsWithChildren<Props>> = ({ children, htmlTagType = 'h1' }) => {
  if (htmlTagType === 'h1') {
    return (
      <div className={styles.container}>
        <h1 className={styles.heading}>{children}</h1>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>{children}</h2>
    </div>
  )
}
